import { getData } from "../services/service";

export const getListClientsFromCPF = async (cpf = null) => {
  if (!cpf) {
    return null;
  }
  /**
   * @type {{error: boolean, data: Array, msg: String}}
   */
  const resp = await getData("/getClientsFromCpf", { cpf, url: window.location.hostname });
  return resp;
};