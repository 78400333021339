import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import Layout from "../components/layout";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  TextField,
  Paper,
  Button,
  Fade,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  LinearProgress,
  Grow,
  withTheme,
  useMediaQuery,
  Link,
  DialogContentText,
  useTheme,
  Breadcrumbs,
  capitalize,
} from "@material-ui/core";

import { ArrowForward, CheckCircle } from "@material-ui/icons";

import "../styles/index.css";
import "../styles/animation.css";

import ArrowBack from "@material-ui/icons/ArrowBack";

import image from "../images/escolanet.png";
import Owl from "../images/loader/owl.svg";
import NumberFormatCustom from "../components/NumberFormatCustom";
import { getListClientsFromCPF } from "../controllers/indexController";

import CPF from "cpf";
import { ERROR, WARNING } from "../utils/messageTypes";

import {
  setSelectedItem,
  setListItems,
  getLastList,
  getSelectedItem,
  setLastCpf,
  getLastCpf,
} from "../utils/savedData";

import { getLocalFromLatLng, getData } from "../services/service";
import CustomDialog from "../components/CustomDialog";
import normalize from "../utils/normalize";
import CustomAutocomplete from "../components/CustomAutocomplete";

const IndexPage = () => {
  const theme = useTheme();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState(ERROR);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [lastValue, setLastValue] = useState("");
  const [showingItems, setShowingItems] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [CPFInvalid, setCPFInvalid] = useState(false);
  const [hideFieds, setHideFieds] = useState(false);
  const [countClick, setCountClick] = useState(0);
  const [dropBook, setDropBook] = useState(false);
  const [lastSelected, setLastSelected] = useState({ gcliente: {} });
  const [onMobileApp, setOnMobileApp] = useState(false);
  const [
    showModalEscolherFormaLocalizacao,
    setShowModalEscolherFormaLocalizacao,
  ] = useState(false);
  const [geolocationState, setGeolocationState] = useState("Denied");

  const [nomeEstado, setNomeEstado] = useState("");
  const [nomeCidade, setNomeCidade] = useState("");
  const [nomeCliente, setNomeCliente] = useState("");

  const [showModalApplyLocation, setShowModalApplyLocation] = useState(false);
  const [showRespQuestion, setShowRespQuestion] = useState(false);
  const [dataSelectLocal, setDataSelectLocal] = useState([]);
  const [
    awaitingForGrantGeolocation,
    setAwaitingForGrantGeolocation,
  ] = useState(false);
  const [step, setStep] = useState(0);
  const largeScreem = useMediaQuery("(min-width: 815px)");
  const [formState, setFormState] = useState({});
  const downsm = useMediaQuery(theme.breakpoints.down("sm"));
  const [errors, setErrors] = useState({});
  const [itemsAcesso, setItemsAcesso] = useState({
    error: false,
    msg: "",
    data: [],
  });
  const [showBackButton, setShowBackButton] = useState(true);
  const buttonNextRef = useRef(null);

  let mobile = false;

  if (typeof window !== "undefined") {
    mobile = !!window.navigator.userAgent.match(/iphone|ipad|android|mobile/gi);
    if (
      window.navigator.userAgent.match(/ipad|iphone|ios/gi) ||
      (
        window.navigator.userAgent.match(/safari/gi) &&
        !window.navigator.userAgent.match(/chrome/gi)
      )
    ) {
      document.body.classList.add("safari");
    }
  }
  // window.alert(window.navigator.userAgent);
  if (mobile) {
    document.body.classList.add("mobile");
  }

  let instituicoes = useMemo(() => {
    if (!nomeCidade || !nomeEstado) {
      return [];
    }

    console.info(
      "Procurando instituições para [nomeCidade =>",
      nomeCidade,
      "Estado=> ",
      nomeEstado
    );

    return dataSelectLocal
      .filter(e => e.nome === nomeEstado)[0]
      .municipios[0].filter(e => {
        return e.nome === nomeCidade;
      })[0].instituicoes[0];
  }, [nomeCidade, nomeEstado]);

  const alert = (text, type = ERROR) => {
    setAlertText(text);
    setAlertType(type);
    clearTimeout(window.timeout);
    setOpenAlert(false);
    setTimeout(() => {
      setOpenAlert(true);
    }, 10);
  };

  const closeAlert = () => {
    setOpenAlert(false);
    if (!window.timeout) {
      window.timeout = setTimeout(() => {
        setAlertText("");
        window.timeout = null;
      }, 1000);
    }
  };

  const getLastSelectedItem = useCallback(async () => {
    const selected = await getSelectedItem();
    if (!selected) {
      return;
    }

    if (selected.cpf) {
      setLastValue(selected.cpf);
      delete selected.cpf;
    }
    setLastSelected(selected);
  }, []);

  const getLastUsedCPF = useCallback(async () => {
    const lastCpf = await getLastCpf();
    if (lastCpf) {
      setLastValue(lastCpf);
    }
  }, []);

  const processSavedItems = useCallback(async () => {
    setLoading(true);
    const items = await getLastList();

    if (items && items.length > 0) {
      setItemsAcesso(last => ({ ...last, data: items }));
    }

    await getLastSelectedItem();
    await getLastUsedCPF();
    setLoading(false);
    return true;
  }, []);

  useEffect(() => {
    processSavedItems().then(() => {
      setTimeout(() => {
        setOnMobileApp(sessionStorage.getItem("onMobileApp") === "true");
      }, 500);
    });

    if (window.navigator && window.navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then(permissions => {
        setGeolocationState(permissions.state);
      });
    } else {
      setGeolocationState("prompt");
    }
  }, []);

  useEffect(() => {
    setShowingItems(itemsAcesso.data.length > 0);
  }, [itemsAcesso]);

  const onSubmit = async e => {
    e.preventDefault();
    if (value.length === 0 && !CPFInvalid) {
      alert("O CPF é obrigatório!");
      setCPFInvalid(true);
      return;
    }
    if (value.replace(/[.]|-/g, "").length < 11) {
      setCPFInvalid(true);
      return;
    }

    if (CPFInvalid) {
      return;
    }

    setLoading(true);
    try {
      const items = await getListClientsFromCPF(value);
      if (items.data.length === 0) {
        alert(
          "Desculpe, não encontramos informações para o CPF informado!",
          WARNING
        );
      }

      setLastCpf(value || lastValue);
      setListItems(items.data); //salva para usar posteriormente

      if (items.data.length === 1) {
        onClickRedirectToItem(items.data[0]); //faz o redirect automatico
      } else {
        setItemsAcesso(items);
      }
    } catch (e) {
      alert(
        "Falha ao verificar informações para o CPF informado, tente novamente mais tarde!",
        WARNING
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  useEffect(() => {
    const hide = loading || showingItems || redirecting;
    if (!hide && window.timeoutHideFields) {
      clearTimeout(window.timeoutHideFields);
    }

    if (hide) {
      window.timeoutHideFields = setTimeout(() => {
        setHideFieds(hide);
        window.timeoutHideFields = null;
      }, 600);
    } else {
      setHideFieds(hide);
    }
  }, [loading, showingItems, redirecting]);

  const onClickRedirectToItem = item => {
    setRedirecting(true);
    setLastCpf(value || lastValue);
    setSelectedItem(item);
    setTimeout(() => {
      window.location.href =
        (item.url.includes("https") ? item.url : "https://" + item.url) +
        "?cpf=" +
        (value || lastValue);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, 2.5 * 1000);
  };

  /**
   *
   * @param {Event} e
   */
  const onClickOwl = e => {
    e.preventDefault();

    if (countClick === 10) {
      return;
    }

    setCountClick(count => count + 1);

    if (dropBook) {
      return;
    }

    if (window.timeoutClickOwl) {
      clearTimeout(window.timeoutClickOwl);
    }

    window.timeoutClickOwl = setTimeout(() => {
      setCountClick(0);
      window.timeoutClickOwl = null;
    }, 1000);
  };

  const onClickVoltar = () => {
    setShowingItems(false);
    setRedirecting(false);
    setLoading(false);
    setItemsAcesso({ data: [], error: false });
  };

  const onClickLinkCadastrar = e => {
    e.preventDefault();
    setStep(0);
    if (geolocationState !== "denied") {
      setShowModalEscolherFormaLocalizacao(true);
      setNomeCidade(null);
      setNomeEstado(null);
    } else {
      setShowRespQuestion(true);
    }
  };

  const redirectToSelectedClient = url => {
    setRedirecting(true);
    setLoading(false);
    let params = "/login.seam?opencadastrarse=true";
    if (value) {
      params += "&cpf=" + value;
    }
    setTimeout(() => {
      window.location.href = "https://" + url + params;
      setTimeout(() => {
        setLoading(false);
        setRedirecting(false);
      }, 1000);
    }, 2.5 * 1000);
    setShowRespQuestion(false);
    setShowModalApplyLocation(false);
    setShowModalEscolherFormaLocalizacao(false);
  };

  const onClickNextStep = () => {
    if (step === 0 && !nomeEstado) {
      alert("Selecione o estado para continuar!", WARNING);
      setErrors(e => ({
        ...e,
        estado: true,
      }));
      return;
    } else if (step == 1 && !nomeCidade) {
      alert("Selecione o município para continuar!", WARNING);
      setErrors(e => ({
        ...e,
        cidade: true,
      }));
      return;
    } else if (step == 2 && !nomeCliente) {
      alert("Selecione uma instituição para continuar!", WARNING);
      setErrors(e => ({
        ...e,
        cliente: true,
      }));
      return;
    } else {
      if (nomeCidade && instituicoes.length === 1) {
        redirectToSelectedClient(instituicoes[0].url);
        return;
      } else if (nomeCidade && nomeCliente && instituicoes.length > 1) {
        const selectedCliente = formState["combo-box-cliente"];
        redirectToSelectedClient(selectedCliente.url);
        return;
      }
      setStep(s => s + 1);
    }
  };

  const onClickBackStep = () => {
    if (step == 2) {
      setNomeCliente("");
      handleChange("cliente", null);
    } else if (step === 1) {
      setNomeCidade("");
      handleChange("cidade", null);
    }
    setStep(s => s - 1);
  };

  const onClickUsarLocal = async () => {
    const dados = await getDadosClientes();
    let cidade =
      nomeCidade && nomeCidade.includes(",")
        ? nomeCidade
          .split(",")[0]
          .trim()
          .toLowerCase()
        : nomeCidade;
    if (cidade.includes("-")) {
      cidade = cidade
        .split("-")[0]
        .trim()
        .toLowerCase();
    }

    const found = dados.find(({ municipios: [municipios = []], nome }, i) => {
      console.log(`procurando ${cidade} em:`, nome);
      if (municipios) {
        let municipio = municipios.find(municipio => {
          if (municipio.nome.toLowerCase() === cidade) {
            return true;
          }
        });

        if (!municipio) {
          municipio = municipios.find(municipio => {
            if (normalize(municipio.nome.toLowerCase()) === normalize(cidade)) {
              return true;
            }
          });
        }

        if (municipio) {
          const itens = municipio.instituicoes[0];
          console.log(municipio);
          if (itens.length > 1) {
            setStep(2);
            setShowRespQuestion(true);
            setShowBackButton(false);
            setNomeCidade(municipio.nome);
            setNomeEstado(nome);
            setFormState({
              "combo-box-estado": dados[i],
              "combo-box-cidade": municipio,
            });

            setShowModalApplyLocation(false);
          } else {
            redirectToSelectedClient(itens[0].url);
          }
        }

        return municipio;
      }
    });

    if (!found) {
      alert(
        `Não encontramos <b>${nomeCidade}</b> na lista de clientes, por favor utilize o modelo de perguntas!`,
        WARNING
      );
    }
  };

  const onClickResponderPerguntas = () => {
    setShowRespQuestion(true);
    setShowBackButton(true);
    setShowModalEscolherFormaLocalizacao(false);
    setNomeCidade("");
    setShowModalApplyLocation(false);
  };

  const getDadosClientes = async () => {
    try {
      const { estados } = await getData(
        "estatistica/dadosClientes",
        {
          url: "https://cdn.omegaeducacional.com/",
        },
        true
      );

      if (window && window.location) {
        const {
          municipios: [municipios],
        } = estados.find(e => e.nome === "Mato Grosso");

        const {
          instituicoes: [instituicoes],
        } = municipios.find(m => m.codigo === 5107958);
        const prefix =
          window.location.href.includes("beta") ||
          window.location.href.includes("test") ||
          window.location.href.includes("localhost")
            ? "beta.ed"
            : "omg.mt.ed";
        instituicoes.push(
          ...[
            {
              id: "omegasistemas",
              nome: "OMEGA",
              url: prefix + ".omegaeducacional.com",
            },
            // {
            //   id: "c114ed",
            //   nome: "C114ED",
            //   url: "ced.mt.ed.omegaeducacional.com",
            // },
          ]
        );
      }

      setDataSelectLocal(estados);
      return estados;
    } catch (e) {
      setShowRespQuestion(false);
      alert(
        "Falha ao carregar informações, aguarde um instante e tente novamente!"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showRespQuestion) {
      setLoading(true);
      setFormState({});
      setNomeEstado(null);
      setNomeCidade(null);
      setNomeCliente(null);

      if (dataSelectLocal.length === 0) {
        getDadosClientes();
      } else {
        setLoading(false);
      }
    }
  }, [showRespQuestion]);

  useEffect(() => {
    if (countClick === 5) {
      setDropBook(true);
    }
  }, [countClick]);

  const handleChange = (name, value) => {
    console.info(name, value);
    setFormState(e => ({ ...e, ["combo-box-" + name]: value }));
    setErrors(e => ({ ...e, [name]: false }));
  };

  const onCLickProcurarLocalizacao = () => {
    setLoading(true);
    setAwaitingForGrantGeolocation(true);
    navigator.geolocation.getCurrentPosition(
      async e => {
        try {
          if (!e.coords.latitude || !e.coords.latitude) {
            throw new Error("Coords not found");
          }
          setAwaitingForGrantGeolocation(false);
          let data = await getLocalFromLatLng(
            e.coords.latitude,
            e.coords.longitude
          );

          if (data instanceof Array) {
            data = data[0].formatted_address;
          }

          if (data) {
            setNomeCidade(data);
            setShowModalApplyLocation(true);
            setLoading(false);
            setShowModalEscolherFormaLocalizacao(false);
          } else {
            alert("Não foi possível obter a localização automática!");
            setLoading(false);
          }
        } catch (e) {
          alert("Não foi possível obter a localização automática!");
          setLoading(false);
          setGeolocationState("denied");
          console.log(e);
        }
      },
      e => {
        setLoading(false);
        console.log(e);
        setAwaitingForGrantGeolocation(false);
        setGeolocationState("denied");
        alert("Não foi possível obter a localização automática!");
      }
    );
  };

  let helperText = undefined;
  if (CPFInvalid && value.length > 0) {
    helperText = "O CPF não é válido!";
  } else if (CPFInvalid && value.length === 0) {
    helperText = "O CPF é obrigatório!";
  }

  const cpf = value || lastValue;

  const containerLoginForm = (
    <div className="container-login-form">
      <Paper
        className="white-circle-bg"
        elevation={6}
        style={{ overflow: "50%" }}
      >
        <Grow in timeout={600}>
          <div
            className={"container-owl-shadow" + (dropBook ? " drop-book" : "")}
            onClick={onClickOwl}
          >
            <Owl />
            <div
              className="shadow-owl"
              onClick={e => e.stopPropagation()}
            ></div>
          </div>
        </Grow>
        <form onSubmit={onSubmit}>
          <Fade in={redirecting || loading} exit>
            <LinearProgress style={{ width: "100%" }} color={"primary"} />
          </Fade>

          {awaitingForGrantGeolocation && geolocationState === "prompt" && (
            <Typography variant="body1">
              Por favor, permita o acesso à sua localização.
            </Typography>
          )}

          {!hideFieds && (
            <>
              <Grow
                enter
                exit
                in={!loading && !showingItems && !redirecting}
                timeout={500}
              >
                <TextField
                  label="Informe seu CPF para acessar"
                  variant="outlined"
                  className="input-cpf"
                  error={CPFInvalid}
                  helperText={helperText}
                  required={true}
                  name="cpf"
                  value={value}
                  onChange={({ target: { value } }) => {
                    setValue(value);
                    if (
                      value.replace(/[.]|-/g, "").length == 11 &&
                      !CPF.isValid(value)
                    ) {
                      setCPFInvalid(true);
                    } else {
                      setCPFInvalid(false);
                    }
                  }}
                  fullWidth
                  size="small"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      format: "###.###.###-##",
                    },
                  }}
                ></TextField>
              </Grow>
              <Grow enter exit in={!loading && !showingItems} timeout={700}>
                <Button
                  className="btn-acessar"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={CPFInvalid && value.length === 14}
                  onClick={onSubmit}
                >
                  Acessar
                </Button>
              </Grow>

              <Grow enter exit in={!loading && !showingItems} timeout={700}>
                <Typography variant="caption" style={{ marginTop: 10 }}>
                  Ainda não possui acesso? Cadastre-se{" "}
                  <Link
                    underline="hover"
                    href="#"
                    onClick={onClickLinkCadastrar}
                  >
                    aqui
                  </Link>
                </Typography>
              </Grow>

              <CustomDialog
                id="question-local"
                open={showModalApplyLocation}
                onCloseFn={setShowModalApplyLocation}
                downsm={downsm}
                actionsStyle={{
                  flexDirection: !largeScreem ? "column" : undefined,
                }}
                title={"Qual a sua localização?"}
                buttonOne={{
                  onClick: onClickUsarLocal,
                  style: {
                    marginBottom: !largeScreem ? 10 : undefined,
                  },
                  text: "Sim, usar este local",
                }}
                buttonTwo={{
                  onClick: onClickResponderPerguntas,
                  text: "Responder perguntas para saber onde eu estou",
                }}
                content={
                  <DialogContentText>
                    Deseja usar <b>{nomeCidade}</b> como local de cadastro?
                  </DialogContentText>
                }
              />

              {geolocationState !== "denied" && (
                <CustomDialog
                  id="escolher-forma-local"
                  open={showModalEscolherFormaLocalizacao}
                  onCloseFn={setShowModalEscolherFormaLocalizacao}
                  downsm={downsm}
                  title={"Qual a sua localização?"}
                  actionsStyle={{
                    flexDirection: !largeScreem ? "column" : undefined,
                  }}
                  buttonOne={
                    geolocationState != "denied"
                      ? {
                          onClick: onCLickProcurarLocalizacao,
                          style: {
                            marginBottom: !largeScreem ? 10 : undefined,
                          },
                          text: "Usar minha localização atual",
                        }
                      : undefined
                  }
                  buttonTwo={{
                    onClick: onClickResponderPerguntas,
                    text: "Responder perguntas para saber onde eu estou",
                  }}
                  content={
                    <>
                      {geolocationState !== "denied" && (
                        <DialogContentText id="dialog-description">
                          Para sabermos onde cadastrar seu perfil precisamos
                          saber a sua localização. Escolha abaixo como deseja
                          informá-la!
                        </DialogContentText>
                      )}
                    </>
                  }
                />
              )}

              <CustomDialog
                open={showRespQuestion && dataSelectLocal.length > 0}
                onCloseFn={setShowRespQuestion}
                downsm={downsm}
                classNameContainer={"dialog-content-select-local"}
                disableBackdropClick
                id="dialog-question-local"
                title={
                  <>
                    {step === 0 && "Qual o seu estado?"}
                    {step === 1 && "Qual o seu município?"}
                    {step === 2 && "Selecione uma instituição"}
                  </>
                }
                buttonOne={
                  showBackButton
                    ? {
                        onClick: onClickBackStep,
                        color: "secondary",
                        disabled: step === 0,
                        text: (
                          <>
                            <ArrowBack /> Voltar
                          </>
                        ),
                      }
                    : undefined
                }
                buttonTwo={{
                  onClick: onClickNextStep,
                  getRef: function(ref) {
                    buttonNextRef.current = ref;
                  },
                  variant: "contained",
                  color: "primary",
                  text: (
                    <>
                      {step < 2 && instituicoes.length !== 1
                        ? "Avançar"
                        : "Finalizar"}{" "}
                      {step < 2 && instituicoes.length !== 1 ? (
                        <ArrowForward />
                      ) : (
                        <CheckCircle />
                      )}
                    </>
                  ),
                }}
                content={
                  <>
                    {step === 0 && (
                      <CustomAutocomplete
                        id="estado"
                        value={formState["combo-box-estado"] || null}
                        label="Estado"
                        helperText={"Selecione o estado e clique em avançar."}
                        error={errors["estado"]}
                        options={dataSelectLocal}
                        onChange={(name, newvalue) => {
                          setNomeEstado(newvalue ? newvalue.nome : null);
                          handleChange(name, newvalue);
                          buttonNextRef.current.focus();
                        }}
                      />
                    )}

                    {step === 1 && (
                      <CustomAutocomplete
                        id="cidade"
                        value={formState["combo-box-cidade"] || null}
                        label="Município"
                        error={errors["cidade"]}
                        helperText={
                          "Selecione o município e clique em " +
                          (instituicoes.length !== 1 && step >= 1
                            ? "avançar."
                            : "finalizar.")
                        }
                        options={
                          dataSelectLocal.filter(e => e.nome === nomeEstado)[0]
                            .municipios[0]
                        }
                        onChange={(name, newvalue) => {
                          setNomeCidade(newvalue ? newvalue.nome : null);
                          handleChange(name, newvalue);
                          buttonNextRef.current.focus();
                        }}
                      />
                    )}

                    {step === 2 && instituicoes.length > 1 && (
                      <CustomAutocomplete
                        id="cliente"
                        value={formState["combo-box-cliente"] || null}
                        label="Instituição"
                        error={errors["cliente"]}
                        helperText={
                          "Selecione a instituição e clique em finalizar."
                        }
                        options={instituicoes}
                        onChange={(name, newvalue) => {
                          setNomeCliente(newvalue ? newvalue.nome : null);
                          handleChange(name, newvalue);
                          buttonNextRef.current.focus();
                        }}
                      />
                    )}

                    <Breadcrumbs className="breadcrumb" separator={"-"}>
                      <Typography
                        variant="caption"
                        color={step === 0 ? "primary" : undefined}
                      >
                        Estado
                      </Typography>
                      <Typography
                        variant="caption"
                        color={step === 1 ? "primary" : undefined}
                      >
                        Município
                      </Typography>
                      {instituicoes.length !== 1 && (
                        <Typography
                          variant="caption"
                          color={step === 2 ? "primary" : undefined}
                        >
                          Instituição
                        </Typography>
                      )}
                    </Breadcrumbs>
                  </>
                }
              />
            </>
          )}

          {showingItems && !redirecting && !loading && (
            <Fade in exit timeout={500}>
              <div className="container-list">
                <Typography
                  variant="caption"
                  style={{ textAlign: "center", fontSize: 14 }}
                >
                  Opções de acesso para o CPF{" "}
                  <b className="cpf">{cpf && CPF.format(cpf)}</b>
                </Typography>
                <List style={{ width: "100%" }}>
                  {itemsAcesso.data.map((item, i) => {
                    const data = item.gcliente.nome.split("-");
                    const nome = data[0].trim();
                    const cidade = capitalize(data[1].trim().toLowerCase());
                    return (
                      <Paper
                        key={i + "-" + item.url}
                        elevation={2}
                        variant={"elevation"}
                        style={{
                          marginBottom: 4,
                          backgroundColor:
                            lastSelected.gcliente.id === item.gcliente.id
                              ? "#75b756"
                              : undefined,
                        }}
                      >
                        <ListItem
                          button
                          style={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            flexDirection: "column",
                            alignItems: "start",
                            color:
                              lastSelected.gcliente.id === item.gcliente.id
                                ? "white"
                                : undefined,
                          }}
                          onClick={() => {
                            onClickRedirectToItem(item);
                          }}
                        >
                          <ListItemText
                            primary={nome}
                            secondary={cidade}
                            secondaryTypographyProps={{
                              style: {
                                color:
                                  lastSelected.gcliente.id === item.gcliente.id
                                    ? "white"
                                    : undefined,
                              },
                            }}
                          ></ListItemText>
                        </ListItem>
                      </Paper>
                    );
                  })}
                </List>
              </div>
            </Fade>
          )}
        </form>
        <div className="container-button">
          <Grow enter exit in={!loading && showingItems} timeout={700}>
            <Button
              className="btn-acessar"
              fullWidth
              variant="contained"
              // color="secondary"
              style={{
                color: "white",
                backgroundColor: "#F44336",
              }}
              onClick={onClickVoltar}
            >
              Voltar
            </Button>
          </Grow>
      </div>  
      </Paper>
    </div>
  );

  return (
    <Layout
      largeScreem={largeScreem}
      easterEggColor={countClick === 10}
      mainClasses={
        (showingItems && !redirecting ? "showing-list" : "") +
        (redirecting ? " redirecting" : "")
      }
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openAlert}
        autoHideDuration={10000}
        onClose={closeAlert}
      >
        <Alert onClose={closeAlert} severity={alertType} variant="standard">
          <>
            {alertText.includes("<b>") && (
              <div dangerouslySetInnerHTML={{ __html: alertText }} />
            )}
            {!alertText.includes("<b>") && alertText}
          </>
        </Alert>
      </Snackbar>

      {showingItems && !largeScreem && !redirecting && !onMobileApp && (
        <Fade in exit>
          <IconButton
            style={{ color: "white", position: "fixed", zIndex: "9" }}
            onClick={onClickVoltar}
          >
            <ArrowBack fontSize="large" />
          </IconButton>
        </Fade>
      )}

      <div className="container-logo">
        <img className="logo" src={image} />
      </div>

      {containerLoginForm}
    </Layout>
  );
};

export default withTheme(IndexPage);
