import React, { useEffect, useState } from "react";
import { TextField, Grow, Select, FormHelperText, FormControl } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const CustomAutocomplete = ({
  options,
  onChange,
  value,
  id,
  style,
  helperText,
  error,
  label,
}) => {
  const mobile = !!window.navigator.userAgent.match(/iphone|ipad|android|mobile/gi);

  return (
    <Grow enter in timeout={500}>
      {mobile ? (
        <FormControl variant="outlined">
          <Select
            native
            value={value && value.nome}
            error={error}
            variant="outlined"
            // noOptionsText="Nenhuma opção encontrada."
            onChange={({ target }) => {
              const itemSel = options.find(e => e.nome === target.value);
              onChange(id, itemSel);
            }}
          >
            <option aria-label="Selecione uma opção" value="">
              Selecione uma opção
            </option>
            {options.map((item, i) => {
              return (
                <option
                  key={item.nome + i}
                  value={item.nome}
                >
                  {item.nome}
                </option>
              );
            })}
          </Select>
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </FormControl>
      ) : (
        <Autocomplete
          id={"combo-box-" + id}
          name={"combo-box-" + id}
          options={options}
          getOptionLabel={option => option.nome}
          style={{ width: "100%", maxWidth: 400, ...style }}
          value={value}
          blurOnSelect="touch"
          noOptionsText="Nenhuma opção encontrada."
          // disableClearable
          onChange={(e, newvalue) => {
            onChange(id, newvalue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              size={"small"}
              helperText={helperText}
              error={error}
              label={label}
              variant="outlined"
            />
          )}
        />
      )}
    </Grow>
  );
};

CustomAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
};

export default CustomAutocomplete;
