import localforage from "localforage";

const KEY_LIST = "lista-items";
const KEY_LAST_SELECTED = "last-selectem-item";
const KEY_LAST_CPF = "last-cpf";

export const getSelectedItem = () => {
  return localforage.getItem(KEY_LAST_SELECTED);
};

export const setSelectedItem = (item) => {
  localforage.setItem(KEY_LAST_SELECTED, item);
};

/**
 * @return {Promise<Array<{}>>}
 */
export const getLastList = () => {
  return localforage.getItem(KEY_LIST);
};

export const setListItems = items => {
  return localforage.setItem(KEY_LIST, items);
};

export const setLastCpf = (cpf) => {
  return localforage.setItem(KEY_LAST_CPF, cpf);
};

export const getLastCpf = () => {
  return localforage.getItem(KEY_LAST_CPF);
};