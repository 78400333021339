import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import DialogTitle from "./CustomDialogTitle";
import PropTypes from "prop-types";

const CustomDialog = ({
  id,
  title = "Titulo não informado",
  open,
  onCloseFn,
  downsm = false,
  content,
  children,
  buttonOne,
  buttonTwo,
  actionsStyle,
  className = "",
  classNameContainer = "",
  disableBackdropClick = false
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        onCloseFn(false);
      }}
      className={className}
      maxWidth="md"
      fullWidth={downsm}
      disableBackdropClick={disableBackdropClick}
      id={id + "__dialog"}
    >
      <DialogTitle
        id={id + "__dialogTitle"}
        onClose={() => {
          onCloseFn(false);
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent className={classNameContainer}>
        {content || children}
      </DialogContent>
      <DialogActions style={actionsStyle}>
        {buttonOne && (
          <Button
            variant={buttonOne.variant || "contained"}
            color={buttonOne.color || "primary"}
            onClick={buttonOne.onClick}
            disabled={buttonOne.disabled}
            style={buttonOne.style}
            className={"button-radius"}
            ref={buttonOne.getRef}
          >
            {buttonOne.text}
          </Button>
        )}

        {buttonTwo && (
          <Button
            variant={buttonTwo.variant || "outlined"}
            onClick={buttonTwo.onClick}
            color={buttonTwo.color}
            disabled={buttonTwo.disabled}
            style={buttonTwo.style}
            ref={buttonTwo.getRef}
            className={"button-radius"}
          >
            {buttonTwo.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const buttonShape = PropTypes.shape({
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  getRef: PropTypes.func,
});

CustomDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired,
  open: PropTypes.bool.isRequired,
  onCloseFn: PropTypes.func.isRequired,
  downsm: PropTypes.bool,
  content: PropTypes.element,
  children: PropTypes.element,
  buttonOne: buttonShape,
  buttonTwo: buttonShape,
  actionsStyle: PropTypes.object,
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
};

export default CustomDialog;
